import React from 'react';
import { useRouter } from 'next/router';
import { useAuthentication } from '~/hooks/useAuthentication';
import { usePlan } from '~/services/stripe.services';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Avatar, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const color = theme.palette.background.paper;
  return {
    userMenuButton: {
      padding: '0',
      borderRadius: '50%',
      minWidth: 0,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& svg': {
        left: 'unset',
        right: 19,
        top: 24,

        '@media only screen and (max-width: 962px)': {
          right: 14,
          top: 18
        }
      },
      '& .user__box': {
        width: 36,
        height: 36,
        borderRadius: '50%',
        padding: 2,

        '&.premium': {
          backgroundImage:
            'conic-gradient(from 0deg at 50% 50%, #FF0000 0deg, #FF7F00 54.38deg, #FFD400 110.62deg, #40BF00 172.5deg, #0086FF 232.5deg, #7C00FF 294.38deg, #FF0086 360deg)'
        },

        '@media only screen and (max-width: 962px)': {
          width: 32,
          height: 32
        },

        '&--wrap': {
          background: '#fff',
          padding: 2,
          position: 'relative',
          display: 'flex',
          borderRadius: '50%'
        }
      }
    },
    avatar: {
      width: '100%',
      height: 'auto',
      borderRadius: '50%',

      '&.small': {
        width: 28,
        height: 28
      }
    }
  };
});

const UserAvatarPremium = () => {
  const router = useRouter();
  const { plan } = usePlan();
  const { user, dispatch, logout } = useAuthentication();
  const rootRef = React.useRef(null);
  const classes = useStyles();

  const handleAvatarClick = () => {
    router.push('/dashboard/settings');
  };

  return (
    <Button ref={rootRef} className={classes.userMenuButton} color="inherit">
      <Box className={clsx('user__box', plan ? 'premium' : '')}>
        <Box className="user__box--wrap">
          {user && user.picture && (
            <img
              className={classes.avatar}
              src={user.picture}
              alt="Profile"
              width="50"
              height="50"
              decoding="async"
            />
          )}
          {user && !user.picture && (
            <Avatar src={user.picture} className={classes.avatar}>
              {user.username.substring(0, 2)}
            </Avatar>
          )}
        </Box>
      </Box>
    </Button>
  );
};

export default UserAvatarPremium;
